<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 유종 리스트 ------------------------------------------------------>

    <CCard border-color="info">
      <CCardHeader>
        <BRow>
          <BCol>
            <BIconDropletFill/> 유종정보
          </BCol>
          <BCol class="text-right">
            <BButton size="sm" variant="primary" class="mb-0" @click="createRow">
              <BIconNodePlusFill/> 유종 추가
            </BButton>
          </BCol>
        </BRow>
      </CCardHeader>

      <CCardBody>


        <BTable
          small
          responsive
          selectable
          select-mode="single"
          selected-variant="primary"
          sticky-header
          sticky-column
          ref="table"
          thead-class="text-center"
          head-variant="light"
          class="text-nowrap small text-center"
          :fields="fields"
          :items="rows"
          @row-selected="rowSelected"
        >

          <template #cell(name)="row">
            <BButton block
                     variant="outline-warning"
                     :style="{borderColor: row.item.color}"
                     v-b-tooltip.hover.left="'환산체적 조회'"
                     @click="showOilTempGroup(row.item.oilTempGroup)"
                     size="sm">
              {{ row.item.name }}
            </BButton>
          </template>

          <template #cell(color)="row">
            <BButton pill block
                     :style="{backgroundColor: row.item.color, color:'black'}"
                     size="sm">{{ row.item.color }}
            </BButton>
          </template>

        </BTable>

        <CCard v-if="row!==null" accent-color="info">
          <BCardHeader>
            <BBadge variant="info"><b>{{row.name}}</b></BBadge> 상세정보
          </BCardHeader>
          <CCardBody>
            <BForm @submit="formSubmit">
              <BFormGroup
                size="sm"
                label="유종코드"
                label-for="code"
                label-cols-md="3"
                valid-feedback="사용 가능한 유종코드 입니다"
                invalid-feedback="1자 이상 4자 이하"
                :state="row.code.length > 0"
              >
                <BFormInput size="sm"
                            id="code"
                            v-model="row.code"
                            :state="row.code.length > 0"
                            maxLength="4"
                            required
                            trim/>
              </BFormGroup>

              <BFormGroup
                size="sm"
                label="유종명"
                label-for="name"
                label-cols-md="3"
                maxLength="20"
                valid-feedback="사용 가능한 유종명 입니다"
                invalid-feedback="2자 이상 20자 이하"
                :state="row.name.length > 1"
              >
                <BFormInput size="sm"
                            id="name"
                            v-model="row.name"
                            :state="row.name.length > 1"
                            maxLength="50"
                            required
                            trim/>
              </BFormGroup>

              <CSelect size="sm"
                       id="oilTempGroup"
                       horizontal
                       label="비중 그룹"
                       :value.sync="row.oilTempGroup"
                       :options="oilTempOpts"
                       :is-valid="row.oilTempGroup!==null"
                       invalid-feedback="비중그룹은 필수 선택입니다"
                       required
              />

              <BFormGroup
                size="sm"
                label="팽창계수"
                label-for="expansionRate"
                label-cols-md="3"
              >
                <BFormInput size="sm"
                            id="expansionRate"
                            type="number"
                            min="0" max="100" step="0.0001"
                            v-model="row.expansionRate"
                            trim/>
              </BFormGroup>

              <BFormGroup
                size="sm"
                label="기준밀도"
                label-for="density"
                label-cols-md="3"
              >
                <BFormInput size="sm"
                            id="density"
                            type="number"
                            min="0" max="100" step="0.0001"
                            v-model="row.density"
                            trim/>
              </BFormGroup>

              <BFormGroup
                size="sm"
                label="유종색상"
                label-for="color"
                label-cols-md="3"
              >
                <BFormInput id="color" size="sm"
                            @click="v=>{pickerShow=!pickerShow}"
                            type="text"
                            v-model="row.color"
                            :style="{backgroundColor: row.color, textShadow:'1px 1px #223322'}"
                            invalid-feedback="색상정보 선택 필수"
                            required
                />
                <swatches-picker id="color-pick"
                                 class="small"
                                 v-show="pickerShow"
                                 v-model="row.color"
                                 @input="v=>{row.color=v.hex;pickerShow=false;}"/>
              </BFormGroup>


              <BFormGroup
                size="sm"
                label="제품코드"
                label-for="prdCode"
                label-cols-md="3"
              >
                <BFormInput size="sm"
                            id="prdCode"
                            type="text"
                            maxLength="50"
                            v-model="row.prdCode"
                            trim/>
              </BFormGroup>

              <BFormGroup
                size="sm"
                label="비축량"
                label-for="stockpile"
                label-cols-md="3"
                valid-feedback="사용가능한 비축량(L) 입니다"
                invalid-feedback="0 이상 입력"
                :state="row.stockpile > 0"
              >
                <BFormInput size="sm"
                            id="stockpile"
                            type="number"
                            min="0" max="999999" step="10"
                            v-model="row.stockpile"
                            :state="row.stockpile > 0"
                            required
                            trim/>
              </BFormGroup>

              <BFormGroup
                size="sm"
                label="1일 소모량"
                label-for="dayConsumption"
                label-cols-md="3"
                valid-feedback="사용가능한 1일 소모량 입니다"
                invalid-feedback="0 이상 입력"
                :state="row.stockpile > 0"
              >
                <BFormInput size="sm"
                            id="dayConsumption"
                            type="number"
                            min="0" max="999999" step="10"
                            v-model="row.dayConsumption"
                            :state="row.dayConsumption > 0"
                            required
                            trim/>
              </BFormGroup>

              <BRow>
                <BCol>
                  <BButton block type="submit"
                           variant="primary"
                           :disabled="row.code==='' || isSubmitting">
                    <div class="sk-plane bg-light float-left" style="height:25px;width:25px" v-if="isSubmitting"/>
                    <BIconSaveFill class="mr-1"/> <b>유종 저장</b>
                  </BButton>
                </BCol>
                <BCol class="text-right">
                  <BButton v-show="isCreateMode"
                           variant="warning"
                           class="mr-1"
                           @click="cancelCreate" :disabled="row===null">
                    <BIconSlashCircleFill/> 취 소
                  </BButton>
                  <BButton v-show="!isCreateMode"
                           variant="danger"
                           @click="deleteRow" :disabled="row===null">
                    <BIconTrashFill/> 삭 제
                  </BButton>
                </BCol>

              </BRow>
            </BForm>



          </CCardBody>
        </CCard>


      </CCardBody>
    </CCard>

    <!--
    <CDataTable
      v-if="false"
      size="sm"
      class="small"
      :items="oils"
      :fields="oilFields"
      items-per-page-select
      :items-per-page="10"
      hover
      sorter
      pagination
      :columnFilter="!simple"
      :tableFilter="!simple"
      cleaner
    >
      <template #color="{item}">
        <td class="align-middle">
          <BButton block
                   :style="{backgroundColor: item.color, color:'black'}" size="sm">{{ item.color }}
          </BButton>
        </td>
      </template>
    </CDataTable>
    -->



  </div>
</template>



<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall, cloneVar
} from '@/common/utils';
import { Swatches } from 'vue-color'

const _oil = {
  code           : '',
  name           : '신규유종',
  oilTempGroup   : '',
  expansionRate  : '',
  density        : '',
  prdCode        : '',
  color          : '',
  stockpile      : '',
  dayConsumption : '',
};


//----------------------------------------------------------------------------------------------------
export default {
  name: "OilList",
  components: {
    'swatches-picker': Swatches,
  },
  props: {
    simple: { type: Boolean, default: false },
  },
  data () {
    return {
      pickerShow: false,
      oilCodeMap: this.$store.state.codeMaps['OIL_CODE'],
      tankTypeMap: this.$store.state.codeMaps['TANK_TYPE'],
      isCreateMode: false,
      rows: [],
      oilTempOpts:[
        {value:null, label:'체적그룹 선택'},
        {value:'1',  label:'그룹 1'},
        {value:'2',  label:'그룹 2'},
        {value:'3',  label:'그룹 3'},
        {value:'4',  label:'그룹 4'},
        {value:'5',  label:'그룹 5'},
        {value:'6',  label:'그룹 6'},
        {value:'7',  label:'그룹 7'},
        {value:'0',  label:'그룹 0'},
      ],
      fields: this.getFields(),
      formShow: false,
      isSubmitting: false,
      row: null,
    }

  },
  async created(){
    try{
      await this.getList();
    }catch(err){
      console.error(err);
    }
  },

  computed: {},

  mounted() {
    console.log("--- OilList mounted---------------------");
    // below is not work!
  },

  methods: {
    showOilTempGroup(groupIndex){
      // console.log('select OilTempGroup---',groupIndex);
      this.$emit("message", groupIndex);
      this.$parent.setGroup(groupIndex);
    },
    async createRow(){
      try{
        this.isCreateMode = true;
        await this.$refs.table.clearSelected();
        this.row = cloneVar(_oil);
      }catch(err){
        console.error(err);
        this.isCreateMode = false;
      }
    },

    async getList(){
      try{
        // console.log( "getOilList() --- query-string ----> ");
        this.rows = [];
        const r = await apiCall('get', `/api/oil`);
        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`유종정보 ${this.rows.length}건 조회됨`, 'info');

        }
        this.selectedTank = null;

      }catch(err){
        await this.alertDanger(err.message);
        console.error( err );
      }

    },
    rowSelected(item){
      // console.log("------------ screenRowSelected ------------", item.length);
      if( item.length === 0 ) {
        this.row = null;
      }else{
        this.row = item.pop();
      }
    },
    getFields(){
      if(!this.simple) {
      return [
        { key: 'code'          ,  sortable: true, tdClass: 'text-center font-weight-bold', label:'유종코드'},
        { key: 'name'          ,  sortable: true, label:'유종명'},
        { key: 'color'         ,  sortable: true, label:'색상'},
        { key: 'oilTempGroup'  ,  sortable: true, label:'체적환산', formatter: (v)=>{ return v? '그룹 '+v: '미설정'} },
        { key: 'expansionRate' ,  sortable: true, label:'팽창계수'},
        { key: 'density'       ,  sortable: true, label:'기준밀도'},
        { key: 'prdCode'       ,  sortable: true, label:'상품코드'},
        { key: 'stockpile'     ,  sortable: true, label:'비축량'},
        { key: 'dayConsumption',  sortable: true, label:'1일소비량'},
        { key: 'updId'         ,  sortable: true, label:'수정자'},
        { key: 'updatedAt'     ,  sortable: true, label:'수정일', formatter: (v)=>{ return this.toLocalTime(v) }},
        { key: 'regId'         ,  sortable: true, label:'등록자'},
        { key: 'createdAt'     ,  sortable: true, label:'등록일', formatter: (v)=>{ return this.toLocalTime(v) }},
      ];
      }else{
        return [
          { key: 'code'          ,  sortable: true, tdClass: 'text-center font-weight-bold', label:'유종코드'},
          { key: 'name'          ,  sortable: true, label:'유종명'},
          { key: 'oilTempGroup'  ,  sortable: true, label:'비중그룹' },
          { key: 'color'         , _style: 'min-width:5%', label:'색상'},
        ];
      }
    },
    async formSubmit(evt){
      let r;
      evt.preventDefault();

      try{
        this.isSubmitting = true;
        if(this.row._id) {
          // 정보 수정
          r = await apiCall("PUT", `/api/oil/${this.row._id}`, this.row);
        }else{
          // 정보 등록
          let checkCode = true;
          this.rows.map(r=>{
            if( r.code===this.row.code ) checkCode = false;
          });
          if(!checkCode) {
            await this.alertDanger('동일코드가 있습니다.', 'E110');
            this.isCreateMode = false;
            return;
          }else{
            r = await apiCall("POST", `/api/oil`, this.row);
          }

        }
        // console.log( r );
        if(r.code===200){
          await this.alertSuccess('유종 정보가 저장 되었습니다.', '유종 정보');
          await this.getList();
        }else{
          await this.toastResult( r, '유종 정보 저장')
        }

      }catch(err){
        console.error( err );
        await this.alertDanger(err.message, r.code, 'danger');
      }finally{
        this.isSubmitting = false;
        this.isCreateMode = false;
      }
    },
    async deleteRow(){
      let r;
      try{
        if(!this.row._id) return;
        if( !(await this.confirmModal('유종정보를 삭제 합니다. 삭제 하시겠습니까?', '유종 삭제')) ){
          return;
        }

        r = await apiCall("DEL", `/api/oil/${this.row._id}`, this.row);
        if(r.code===200){
          await this.alertSuccess('유종 정보가 삭제 되었습니다.', '유종 정보');
          await this.getList();
        }else{
          await this.toastResult( r, '유종 정보 삭제')
        }
      }catch(err){
        console.error(err);
      }
    },
    cancelCreate(){
      this.row = null;
      this.isCreateMode = false;
    }

  } // end of methods
}
</script>
